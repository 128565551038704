import {
  Row,
  Col,
  Card,
  Avatar,
} from "antd";

import BgProfile from "../assets/images/bg-profile.jpg";
import profilavatar from "../assets/images/newlogo.jpg";

function About() {

  return (
    <>
      <div
        className="about-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      ></div>

      <Card
        className="card-about-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={12} className="col-info">
              <Avatar.Group>
                <Avatar size={74} shape="square" src={profilavatar} />

                <div className="avatar-info">
                  <h4 className="font-semibold m-0">Anoid Apps</h4>
                  <p>anoidapps.com</p>
                </div>
              </Avatar.Group>
            </Col>
          </Row>
        }
      ></Card>

      <Card
        bordered={false}
        className="header-solid mb-24"
      >
            <h1>Welcome to Anoid Apps!</h1>
            <p>We are a team of experienced developers, designers, and project managers who specialize in creating custom software solutions for businesses of all sizes. Our goal is to help our clients streamline their processes, increase efficiency, and ultimately achieve their business objectives through technology.</p>

            <h2>Services We Offer</h2>
            <ul>
            <li>Custom software development</li>
            <li>Mobile app development</li>
            <li>Web development</li>
            <li>Cloud solutions</li>
            </ul>

            <h2>Our Approach</h2>
            <p>We believe that every project is unique and requires a personalized approach. That's why we work closely with our clients to understand their business objectives, goals, and challenges. Our team of experts will work with you to develop a customized solution that fits your business needs, timeline, and budget.</p>

            <p>Our team follows an Agile methodology that emphasizes collaboration, transparency, and flexibility. We use industry-standard tools and technologies to ensure that our solutions are scalable, secure, and maintainable.</p>

            <h2>Why Choose Us</h2>
            <ul>
            <li>Experience: Our team has years of experience in developing software solutions for a wide range of industries and businesses.</li>
            <li>Quality: We are committed to delivering high-quality solutions that meet our clients' needs and exceed their expectations.</li>
            <li>Communication: We believe that communication is key to a successful project, and we keep our clients informed at every stage of the development process.</li>
            <li>Support: We provide ongoing support to ensure that our clients' solutions continue to meet their evolving business needs.</li>
            </ul>

            <h2>Contact Us</h2>
            <p>If you are interested in learning more about our services or want to discuss your project with us, please <a href="mailto:service@anoidapps.com">contact us</a> through our website or give us a call. We look forward to hearing from you and helping you achieve your business goals through technology.</p>
      </Card>
    </>
  );
}

export default About;
